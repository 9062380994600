import React from "react";

// componenents
import Layout from "../components/Layout";
import { useAxiosCall } from '../hooks/useAxiosCall'
import CreateANewAccountForm from "../components/UserSystem/CreateANewAccountForm";
import { CreateANewAccountOnSubmit as onFormSubmit } from '../components/UserSystem/CreateANewAccountOnSubmit'

// markup
const CreateANewAccount = () => {
  const axiosCall = useAxiosCall()
  // const onFormSubmit = (username, email) => {

  //   const url = 'https://dev-tuckel.pantheonsite.io/user/password?_format=json'
  //   const method = 'post'
  //   const payload = {
  //       _links:{type:{href:siteUrl + '/rest/type/user/user'}},
  //       name:[{ value:username }],
  //       mail:[{ value:email }]
  //   }

  //   const onSuccess = (response) => {
  //     // notify the user of success via the alert toaster
  //     messageService.sendMessageAutoClose('', 'success');

  //     console.log('[CreateANewAccount] success: ', response)
  //   }

  //   const onFailure = (e) => {
  //     console.log('[CreateANewAccount] error: ', e.response)
  //     const msg = e.response.data.message
  //     messageService.sendMessageAutoClose(msg, 'error');
  //   }
  //   axiosCall (url, method, payload, onSuccess, onFailure)

  // }
  // onFormSubmit()

  return (
    <Layout>
      <CreateANewAccountForm onFormSubmit={() => {onFormSubmit (axiosCall) } }/>
    </Layout>
  )
};

export default CreateANewAccount;