import React, { useState, useContext }  from "react";

import { FormErrors, FormCheckbox, ExplainerText } from "../LoginForm/styles"

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";

import { Link } from "gatsby"
import UserSystemNavigation from "../Navigation";

// display a gatsby fluid image
const CreateANewAccountForm = ({ onFormSubmit }) => {
  if (!onFormSubmit) {
    throw new Error ('a onFormSubmit handler was not provided. Please provide one so the form can work correctly.')
  }

  const usernameInitial = '';
  const emailInitial = '';
  // const emailInitial = (devMode) ? 'bettye.fritsch@corwin.com' : '';

  const [username, setUsername] = useState(usernameInitial);
  const [email, setEmail] = useState(emailInitial);
  
  const [errorMsg, setErrorMsg] = useState("");

  function validateForm() {
    return (email.length > 0 && username.length > 0)
  }

  async function HandleSubmit(event) {
    event.preventDefault();
    if (!email.length > 0) {
      // todo: writ this
      // setEmailWarning("Error: email address cannot be empty");
    } else {
      onFormSubmit(email)
    }
    
    return true

  }

  const emailWarning = ''
  const usernameWarning = ''

  return (
    <>
        <div className="Login">
        {errorMsg && (
          <FormErrors>
            {errorMsg}
          </FormErrors>
          )
        }
            
        <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
          <div style={{width:'600px'}}>
            <h2>Create a new account:</h2>
            <UserSystemNavigation currentRoute="/CreateANewAccount" />
            
            <br /><br />
            <Form onSubmit={HandleSubmit}>
              <Form.Group size="lg" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoFocus
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <ExplainerText>
                  All emails from the system will be sent to this address. The email address is not made public and will only be used if you wish to receive a new password or wish to receive certain news or notifications by email.
                </ExplainerText>
                <div>
                  {emailWarning}
                </div>
              </Form.Group>
              <Form.Group size="lg" controlId="email">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <ExplainerText>
                  Several special characters are allowed, including space, period (.), hyphen (-), apostrophe ('), underscore (_), and the @ sign.
                </ExplainerText>
                <div>
                  {usernameWarning}
                </div>
              </Form.Group>

              <Button block size="lg" type="submit" disabled={!validateForm()}>
                Create new account
              </Button>
            </Form>
          </div>
        
        </div>
      </div>
    </>
  );
    
}

export default CreateANewAccountForm